<template>
  <div>
    <b-input-group>
      <template #prepend>
        <b-form-select
          :options="phoneCodeList"
          :value="phoneCode"
          style="background-color: #f4f4f4;"
        />
      </template>
      <b-form-input
        v-model="customerPhone"
        type="number"
        :class="requireCustomerPhone ? 'bg-[#FFEDED] border-[1px] border-[#E31A1A]' : 'bg-[ffffff]'"
        :formatter="validatePhone"
        @update="checkWhatsapp"
        @paste.prevent="handlePaste"
        @keypress="blockCharacter($event)"
      />
      <template
        v-if="sendTest"
        #append
      >
        <button
          class="btn-send"
          type="button"
          :disabled="requireCustomerPhone || validateSendTest || requireCustomerPhone === null || isWhatsapp === 'invalid'"
          @click="sendDemo"
        >
          <img
            :src="requireCustomerPhone || validateSendTest || requireCustomerPhone === null || isWhatsapp === 'invalid' ? 'https://storage.googleapis.com/komerce/assets/icons/send-chat.svg' : 'https://storage.googleapis.com/komerce/assets/svg/send-message.svg'"
            alt="send"
          >
        </button>
      </template>
    </b-input-group>
    <div v-if="messageError !== ''">
      <small class="mr-1 text-primary">
        *{{ messageError }}
      </small>
    </div>
    <div
      v-if="whatsappStatus !== null"
      :class="['d-flex', whatsappStatus.textColor]"
      style="margin-top: 5px;"
    >
      <span
        class="k-Whatsapp text-[22px]"
      />
      <span
        class="my-auto"
        style="margin-left: 0.2vw;font-size:12px"
      >{{ whatsappStatus.message }}</span>
      <img
        v-if="whatsappStatus.content !== '' && tooltip"
        id="whatsapp-info"
        class="my-auto ml-[5px]"
        src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
        alt="info"
      >
      <b-popover
        triggers="hover"
        target="whatsapp-info"
        placement="topright"
        :content="whatsappStatus.content"
        custom-class="info-wa"
        variant="info"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    valuePhone: {
      type: String,
      default: '',
    },
    checkWa: {
      type: Boolean,
      default: false,
    },
    phoneUsed: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    sendTest: {
      type: Boolean,
      default: false,
    },
    validateSendTest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneCode: '+62',
      phoneCodeList: ['+62'],
      customerPhone: '',
      requireCustomerPhone: null,
      messageError: '',
      isWhatsapp: null,
    }
  },
  computed: {
    whatsappStatus() {
      if (this.isWhatsapp === 'valid') {
        return {
          message: 'Nomor ini terhubung ke Whatsapp',
          content: 'Kurir akan lebih mudah berkoordinasi',
          textColor: 'text-[#34A770]',
        }
      } if (this.isWhatsapp === 'invalid') {
        return {
          message: 'Nomor ini tidak terhubung ke Whatsapp',
          content: 'Kurir akan kesulitan dalam berkoordinasi',
          textColor: 'text-[#E31A1A]',
        }
      } if (this.isWhatsapp !== null) {
        return {
          message: 'Maaf, terdapat masalah saat melakukan cek nomor',
          content: '',
          textColor: 'text-[#FBA63C]',
        }
      }
      return null
    },
  },
  watch: {
    valuePhone: {
      handler(newVal) {
        if (newVal.length > 9) {
          let phone = newVal
          const startsWithEight = newVal.startsWith('8')
          if (!startsWithEight) {
            phone = newVal.slice(1)
          }
          this.customerPhone = this.validatePhone(phone)
          this.checkWhatsapp()
        }
      },
      immediate: true,
    },
    requireCustomerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
    customerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:dataForParent', {
        customerPhone: this.customerPhone,
        requireCustomerPhone: this.requireCustomerPhone,
        isWhatsapp: this.isWhatsapp,
        messageError: this.messageError,
        phoneCode: this.phoneCode,
      })
    },
    blockCharacter($event) {
      this.messageError = ''
      const key = ['e', '+', '.', '-', ',']
      if (key.includes($event.key)) {
        $event.preventDefault()
        this.messageError = 'Wajib di mulai angka 8'
      } else {
        this.validatePhone($event.target.value)
      }
    },
    validatePhone(value) {
      this.messageError = ''
      const phone = value.replace(/[^\d]/g, '')
      const startsWithEight = phone.startsWith('8')
      const isRequired = phone.length === 0
      this.requireCustomerPhone = phone.length < 9 || phone.length > 15 || !startsWithEight || isRequired

      if (isRequired) {
        this.messageError = 'Wajib diisi ya'
        this.isWhatsapp = null
        return value
      }

      if (value.startsWith('62')) {
        this.isWhatsapp = null
        return value.slice(2)
      }

      if (!startsWithEight) {
        this.messageError = 'Wajib di mulai angka 8'
        this.isWhatsapp = null
        return value.slice(1)
      }

      if (phone.length < 9) {
        this.messageError = 'Minimal 9 angka'
        this.isWhatsapp = null
      }

      if (phone.length > 15) {
        this.messageError = 'Maksimal 15 karakter'
        return value.substring(0, 15)
      }

      if (this.phoneUsed && !this.requireCustomerPhone) {
        this.requireCustomerPhone = true
        this.checkPhoneUsed()
      }
      return value
    },
    checkPhoneUsed: _.debounce(async function () {
      await this.$http_new.get(`/auth/api/v1/user/setting/phone/check-used?phone=62${this.customerPhone}`)
        .then(response => {
          const { data } = response.data
          if (data.is_used) {
            this.messageError = 'Nomor Hp sudah digunakan orang lain'
            this.requireCustomerPhone = true
            this.isWhatsapp = null
          }
          this.requireCustomerPhone = false
        })
        .catch(err => {
          console.log(err.response)
        })
      this.updateValue()
    }, 1000),
    checkWhatsapp: _.debounce(async function () {
      if (String(this.customerPhone).length >= 9 && this.checkWa) {
        await this.$http_komship.post(`/v1/check-wa?phone_no=${this.customerPhone}`)
          .then(res => {
            this.isWhatsapp = res.status === 200 ? 'valid' : null
          })
          .catch(error => {
            this.isWhatsapp = error.response.status === 400 ? 'invalid' : ''
          })
        this.updateValue()
      } else {
        this.isWhatsapp = null
      }
    }, 500),
    handlePaste(event) {
      const pasteContent = (event.clipboardData || window.clipboardData).getData('text')
      const sanitizedContent = pasteContent.replace(/\D/g, '')
      this.customerPhone = sanitizedContent
      const index = this.customerPhone.indexOf('8')
      if (index !== -1) {
        const secondPart = this.customerPhone.substring(index)
        this.customerPhone = `${secondPart}`
      }
      this.checkWhatsapp()
      event.preventDefault()
    },
    sendDemo() {
      this.$emit('send-demo')
    },
  },
}
</script>
<style lang="scss">
.info-wa {
  background-color: #BEE6FC !important;
  font-size: 12px !important;
  color: #08A0F7 !important;
}
.btn-send {
  border: 1px solid #d8d6de;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 35px;
  text-align: -webkit-center;
}

.btn-send:focus {
  outline-color: #F95031 !important;
}
</style>
